<script setup lang="ts">
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchClickEvent, prd_typeList } from '~/types/event-tracking'

defineProps({
  alignOffset: {
    type: Number,
    default: 5,
  },
  sideOffset: {
    type: Number,
    default: 0,
  },
  refrehsOnConfirm: {
    type: Boolean,
    default: false,
  },
  pageType: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['searchConfirm'])
const { bgImg } = usePartnerConfig()
const { t } = useI18n()
const showPopup = ref(false)
const { searchInput, clearSearch, watchSearchInput, onConfirmAttr } = useSearchAttr()
const { resetExperienceFilter } = useExperienceList()
const { resetListFilter } = useFilterConditionsStore()

const filterConditionStore = useFilterConditionsStore()
const selectCityName = computed(() => {
  return filterConditionStore.filterConditions.selectedCity?.destinationName
})

const showSearchPopup = ref(false)
function confirmCity() {
  showPopup.value = false
  showSearchPopup.value = false
}
function confirmAttr() {
  showPopup.value = false
  showSearchPopup.value = false
}
function handleSearch() {
  if (searchInput.value) {
    trackSearchClickEvent()
    emit('searchConfirm')
    onConfirmAttr()

    resetExperienceFilter()
    resetListFilter()
  }
}
const gtm = useGtm()
const filterConditionsStore = useFilterConditionsStore()
const dayjs = useDayjs()
const { query } = useRoute()
const { locale } = useLocale()

function trackSearchClickEvent() {
  const prdMap: { [key: number]: prd_typeList[number] } = {
    0: 'All',
    1: 'Attractions',
    2: 'Experiences',
  }
  const tabKey = Number(query.tab_key) || 0
  const eventParams: SearchClickEvent = {
    event: ENUM_EventType.Search,
    page_category: ENUM_PageName.List,
    city: filterConditionsStore.filterConditions.selectedCity?.destinationName,
    city_id: filterConditionsStore.filterConditions.selectedCity?.destinationId,
    country_code: filterConditionsStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterConditionsStore.filterConditions.date).format('YYYY-MM-DD'),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
    search_term: searchInput.value,
    prd_type: prdMap[tabKey] ?? 'All',

  }
  gtm?.trackEvent(eventParams)
}

function handleClearSearch() {
  clearSearch()
  navigateToList({ replace: true })
}

watchSearchInput()
</script>

<template>
  <div
    class="search-attr-popup list-top w-full" flex="~ justify-center items-center" p="t-7 b-9"
    :style="{
      background: 'center center no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url(${bgImg})`,
      backgroundColor: 'var(--tc-color-bg-list)',
    }"
  >
    <div text="2xl white" font="600" :style="{ color: 'var(--tc-color-title-list)' }">
      {{ t('key.attractions.list.things.to.do.in') }}
    </div>
    <BaseAZPopover v-model:open="showPopup" :show-close-icon="false" align="start" :align-offset="alignOffset" :side-offset="sideOffset">
      <template #reference>
        <div class="flex items-center">
          <div @click.stop="showPopup = !showPopup">
            <div flex="~ items-center" m="t-1 b-7">
              <div text="5xl white" font="600">
                {{ selectCityName }}
              </div>
              <div m="t-2 l-2" h-25px w-25px flex items-center justify-center rounded-full bg-black>
                <ElIcon><ElIconArrowDownBold color="white" /></ElIcon>
              </div>
            </div>
          </div>
        </div>
      </template>
      <DesktopSearchCity @confirm="confirmCity"></DesktopSearchCity>
    </BaseAZPopover>
    <div bg="[#F3F3F3]" max-w-250 w-full flex flex-nowrap rounded-4 p="y-1.5 x-2">
      <BaseAZPopover v-model:open="showSearchPopup" w-full :show-close-icon="false" align="start" :align-offset="-8" :side-offset="12">
        <template #reference>
          <div class="h-12 flex items-center bg-[#F3F3F3]">
            <NuxtIcon name="my-icon:search" m="l-4" text="3xl #8D8D8D"></NuxtIcon>
            <input
              v-model="searchInput"
              flex-1 width="100%"
              class="2xl:text-base h-12 px-4 font-300 leading-9"
              text="base #000"
              :placeholder="t('key.search.attrs.placeholder')"
              @click.stop="showSearchPopup = !showSearchPopup"
              @keyup.enter="handleSearch"
            />
            <ElIcon
              v-if="!!searchInput" size="24" color="#aaa" @click.stop="handleClearSearch"
            >
              <ElIconCircleCloseFilled></ElIconCircleCloseFilled>
            </ElIcon>
          </div>
        </template>
        <DesktopSearchAttrs @confirm="confirmAttr"></DesktopSearchAttrs>
      </BaseAZPopover>
      <div bg="#B3B3B3" mx-4 w-1px></div>
      <div>
        <DesktopSelectDate flex="1" :is-detail="false">
          <template #default="{ formatDate }">
            <div
              h="12"
              flex="~ 1 items-center"
              bg="#F3F3F3"
            >
              <img :width="30" src="~assets/icons/icon-calendar.png" />
              <div ml-2 class="flex-shrink-0 truncate text-lg text-[#1B1B1B] font-500">
                {{ formatDate }}
              </div>
            </div>
          </template>
        </DesktopSelectDate>
      </div>
      <div
        m="l-10"
        p="x-7.5 y-2.5"
        cursor="pointer"
        text="xl white center"
        class="block rounded-2xl bg-black font-800"
        @click="handleSearch"
      >
        <NuxtIcon name="my-icon:search" class="align-middle" text="3xl"></NuxtIcon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.list-top {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 18px 20px 18px;
    background-color: white;
    border-radius: 22px;
    height: 36px;

    &-left {
      height: 36px;
      background-color: #000000;

      display: flex;
      flex-direction: row;
      align-items: center;

      max-width: 200px;
      border-radius: 22px;

      &-city {
        margin-left: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;

        // 可以被压缩，点点点
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-triangle {
        margin: 8px 12px 0 10px;
        // margin-left: 6px;
        // margin-right: 12px;
        // width: 21px;
        // height: 21px;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: 8px solid #ffffff;
      }
    }

    &-right {
      flex: 1;
      &-t {
        margin-left: 10px;
        color: #b3b3b3;

        font: {
          size: 12px;
          weight: 300;
        }
      }
    }
  }
}
.city-item {
  cursor: pointer;
  &:hover {
    color: var(--tc-color-primary);
  }
}
.search-attr-popup {
  :deep(>button:last-child) {
    flex: 1;
    input {
      flex: 1;
    }
    .el-icon {
      cursor: pointer;
      width: 2.5rem;
      height: 2rem;
      padding-right: 1rem;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
</style>
